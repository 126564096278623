import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1002.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1002.000000) scale(0.100000,-0.100000)">



<path d="M1460 5330 l0 -2810 3870 0 3870 0 0 2810 0 2810 -3870 0 -3870 0 0
-2810z m7035 2610 c10 -11 25 -20 34 -20 9 0 34 -9 56 -20 22 -11 51 -20 66
-20 14 0 34 -9 45 -21 10 -11 54 -45 97 -76 52 -36 83 -65 92 -86 7 -18 25
-45 39 -60 29 -32 57 -83 75 -139 7 -20 19 -42 27 -48 12 -11 14 -276 14
-1995 0 -1954 0 -1983 -20 -2026 -11 -24 -20 -54 -20 -65 0 -12 -14 -39 -31
-60 -18 -22 -47 -71 -66 -110 -31 -64 -41 -76 -109 -123 -41 -28 -83 -51 -93
-51 -10 0 -24 -7 -31 -15 -6 -8 -37 -26 -67 -40 l-55 -25 -3180 0 -3179 0 -27
25 c-34 31 -83 55 -114 55 -35 0 -202 171 -234 239 -13 29 -24 59 -24 65 0 7
-8 21 -19 30 -11 10 -21 37 -25 65 -4 27 -13 54 -21 61 -23 19 -23 3832 0
3900 8 25 17 57 19 71 3 14 15 36 26 49 12 14 20 37 20 61 0 35 9 47 140 178
134 135 142 141 181 141 26 0 47 7 61 20 12 11 32 20 44 20 12 0 41 9 65 20
44 19 77 20 3120 20 3063 0 3076 0 3094 -20z"/>
<path d="M5283 7836 c-15 -13 -104 -72 -198 -131 -93 -59 -237 -151 -320 -203
-82 -52 -278 -177 -435 -277 -157 -100 -373 -237 -480 -305 -107 -68 -251
-159 -320 -203 -69 -44 -168 -107 -220 -140 -52 -33 -124 -79 -160 -102 -36
-23 -212 -135 -392 -250 -181 -114 -328 -214 -328 -221 0 -8 11 -42 24 -76 14
-35 54 -142 90 -238 35 -96 94 -256 131 -355 36 -99 138 -371 225 -605 152
-407 357 -960 535 -1440 46 -124 85 -234 87 -245 3 -20 12 -20 1794 -23 l1792
-2 25 77 c13 43 82 231 152 418 166 444 415 1114 561 1510 60 164 150 406 259
698 59 159 106 290 104 292 -4 3 -186 120 -292 187 -37 24 -86 54 -107 68 -22
14 -102 64 -177 112 -76 48 -243 154 -370 235 -575 366 -823 524 -893 567 -41
26 -208 132 -370 236 -162 104 -368 234 -456 290 -89 55 -174 112 -188 125
-33 31 -38 31 -73 1z m290 -310 c133 -84 393 -249 577 -366 184 -117 364 -232
400 -255 36 -23 202 -129 370 -235 582 -369 1105 -702 1113 -709 4 -4 -38
-131 -94 -282 -55 -151 -129 -350 -164 -444 -34 -93 -102 -276 -150 -405 -48
-129 -116 -311 -150 -405 -57 -153 -379 -1018 -442 -1188 -18 -49 -31 -69 -47
-72 -11 -3 -770 -4 -1686 -3 l-1664 3 -13 25 c-7 14 -65 167 -129 340 -64 173
-208 563 -321 865 -112 303 -263 708 -334 900 -71 193 -134 361 -140 374 -26
62 -99 271 -99 283 0 7 55 47 122 88 67 41 151 94 187 117 36 23 95 60 131 83
36 22 146 93 245 156 99 63 207 132 240 153 33 21 204 130 380 242 176 111
399 253 495 314 96 61 309 196 472 300 429 274 431 275 446 275 8 0 123 -69
255 -154z"/>
<path d="M6075 5963 c-159 -57 -272 -188 -322 -374 -23 -87 -23 -289 0 -379
39 -147 128 -270 238 -326 79 -40 192 -57 284 -45 148 21 234 91 278 226 26
80 31 147 13 170 -25 33 -48 8 -62 -66 -31 -168 -103 -246 -239 -256 -77 -7
-131 8 -184 50 -104 83 -151 226 -151 462 0 236 45 375 143 442 151 102 322 8
378 -207 l12 -45 39 85 c21 46 58 118 80 159 39 71 40 74 17 67 -68 -20 -97
-18 -190 14 -121 41 -256 51 -334 23z"/>
<path d="M4377 5940 c-172 -51 -299 -188 -342 -371 -19 -82 -19 -246 0 -328 9
-35 29 -90 46 -122 40 -75 139 -173 209 -209 83 -42 172 -51 473 -50 263 2
270 1 291 -20 l21 -21 -340 4 c-187 2 -353 7 -370 11 -27 6 -26 5 10 -16 40
-23 42 -23 389 -21 191 1 350 3 351 5 2 2 -25 37 -61 77 l-64 74 0 202 0 201
40 39 c37 36 51 65 32 65 -4 0 -29 -22 -55 -48 l-47 -48 0 -209 0 -209 28 -29
c31 -34 11 -25 -37 15 l-31 27 0 208 0 207 57 58 57 58 -301 0 c-191 0 -304 4
-308 10 -3 6 3 10 15 10 12 0 25 10 32 25 22 48 -43 98 -88 67 -34 -22 -44
-93 -19 -129 23 -34 76 -43 238 -43 142 0 147 -1 147 -21 0 -20 -3 -21 -163
-17 -122 2 -168 7 -181 17 -22 18 -34 5 -14 -15 24 -24 88 -33 231 -34 l127 0
0 -215 0 -215 -113 0 c-182 0 -273 40 -346 153 -65 99 -94 275 -72 440 21 154
79 262 174 321 40 24 61 30 128 34 152 9 232 -50 279 -208 l16 -55 72 143 c40
78 72 146 72 151 0 6 -13 6 -32 0 -52 -15 -106 -10 -208 16 -133 36 -255 41
-343 15z"/>
<path d="M5386 5950 l-189 -5 56 -45 56 -45 1 -446 0 -447 -55 -43 c-30 -24
-54 -48 -55 -52 0 -4 91 -7 202 -6 196 1 203 1 216 -20 l14 -21 -202 0 c-179
0 -201 -2 -190 -15 10 -12 48 -15 226 -15 148 0 214 3 214 11 0 6 -29 43 -65
82 l-65 71 0 431 0 431 40 39 c37 36 51 65 32 65 -4 0 -29 -22 -55 -48 l-47
-48 0 -436 0 -436 26 -31 c35 -41 23 -39 -26 5 l-39 36 -1 434 0 435 55 59
c31 32 53 60 48 62 -4 1 -93 1 -197 -2z"/>
<path d="M6647 5877 c-9 -14 -46 -82 -83 -152 -47 -89 -63 -130 -56 -137 7 -7
27 23 67 102 31 62 68 131 82 153 15 24 21 43 15 49 -5 5 -15 0 -25 -15z"/>
<path d="M4997 5873 c-23 -38 -137 -264 -137 -272 0 -6 4 -11 10 -11 8 0 131
230 146 273 5 16 -10 25 -19 10z"/>
<path d="M6135 5846 c-106 -46 -165 -234 -151 -481 12 -223 70 -358 180 -422
26 -15 36 -16 36 -4 0 5 -9 12 -19 16 -59 18 -129 139 -155 267 -32 157 -16
411 33 506 60 117 172 145 284 69 30 -20 37 -9 10 15 -54 47 -153 62 -218 34z"/>
<path d="M4509 5839 c-108 -16 -180 -93 -220 -234 -9 -33 -14 -101 -14 -200 0
-156 11 -227 47 -298 22 -44 82 -113 112 -129 14 -7 8 3 -16 30 -95 105 -118
182 -118 392 0 151 16 238 54 299 72 113 201 149 321 88 22 -11 43 -25 47 -31
4 -6 10 -7 15 -3 8 9 -24 38 -70 63 -39 21 -105 31 -158 23z"/>
<path d="M6616 5150 c-16 -140 -65 -231 -159 -293 -65 -43 -104 -56 -186 -63
-75 -7 -185 13 -250 44 -23 11 -38 16 -35 11 10 -17 99 -57 155 -69 32 -7 92
-11 140 -8 145 7 251 74 309 192 32 66 66 205 57 230 -12 32 -25 15 -31 -44z"/>
<path d="M3728 4080 c-39 -30 -51 -70 -46 -154 3 -75 17 -104 58 -126 13 -7
15 8 15 123 0 73 4 142 9 155 10 28 -3 29 -36 2z"/>
<path d="M3780 4087 c0 -5 8 -15 17 -22 9 -7 22 -28 28 -46 17 -48 30 -38 30
22 l0 51 -37 3 c-21 1 -38 -3 -38 -8z"/>
<path d="M4071 4088 c-48 -17 -77 -144 -52 -221 23 -70 97 -96 142 -50 22 23
28 55 12 60 -7 3 -13 -2 -13 -9 0 -8 -10 -24 -23 -36 -38 -36 -47 -13 -47 115
0 96 3 115 16 120 20 7 43 -19 51 -60 13 -62 33 2 24 78 -1 11 -82 14 -110 3z"/>
<path d="M5091 4074 c-52 -44 -66 -144 -31 -222 11 -24 29 -46 42 -51 28 -11
48 3 30 21 -15 15 -17 234 -2 243 13 8 13 35 1 35 -5 0 -23 -12 -40 -26z"/>
<path d="M5150 4084 c0 -6 7 -17 14 -24 8 -6 20 -27 26 -46 18 -52 31 -41 28
23 -3 56 -3 56 -36 57 -17 1 -32 -4 -32 -10z"/>
<path d="M5292 4084 c-36 -25 -53 -69 -53 -138 -1 -104 39 -158 107 -147 53 9
77 46 82 125 5 76 -10 128 -45 156 -14 11 -28 20 -32 20 -3 0 -4 -59 -2 -130
1 -72 0 -138 -4 -148 -6 -16 -7 -16 -21 2 -17 24 -20 207 -3 238 15 28 -2 41
-29 22z"/>
<path d="M5862 4084 c-36 -25 -53 -70 -54 -144 -1 -102 36 -152 107 -145 31 4
64 39 65 68 0 23 -17 22 -25 -2 -3 -11 -16 -26 -29 -34 -22 -15 -24 -14 -36 7
-13 25 -10 201 5 244 9 26 -2 28 -33 6z"/>
<path d="M5913 4087 c-3 -5 4 -18 15 -30 11 -12 24 -34 27 -49 12 -47 25 -31
25 30 l0 57 -31 0 c-17 0 -33 -4 -36 -8z"/>
<path d="M6052 4084 c-37 -25 -52 -67 -52 -142 0 -55 5 -76 23 -103 21 -31 27
-34 72 -34 47 0 50 2 72 40 41 73 25 201 -31 240 -11 8 -23 15 -26 15 -3 0 -3
-61 -1 -136 3 -138 -5 -178 -29 -135 -12 24 -9 210 4 249 10 26 -1 28 -32 6z"/>
<path d="M6838 4080 c-39 -30 -51 -70 -46 -156 3 -70 6 -78 35 -104 51 -46
133 -24 133 35 0 18 3 47 6 64 l7 31 -47 0 c-34 0 -46 -4 -46 -15 0 -8 5 -15
10 -15 13 0 13 -80 0 -100 -5 -8 -13 -11 -17 -6 -11 10 -10 224 1 259 10 32
-1 35 -36 7z"/>
<path d="M6890 4087 c0 -5 8 -15 17 -22 9 -7 22 -28 28 -46 17 -48 30 -38 30
22 l0 51 -37 3 c-21 1 -38 -3 -38 -8z"/>
<path d="M3895 3945 l0 -145 45 0 45 0 0 145 0 145 -45 0 -45 0 0 -145z"/>
<path d="M4290 4039 c0 -36 4 -49 13 -46 6 2 13 9 13 15 1 7 2 18 3 25 0 7 8
22 16 33 14 17 15 7 12 -106 -1 -69 -6 -133 -11 -142 -7 -16 -2 -18 48 -18 52
0 70 10 46 25 -6 4 -10 60 -10 136 l0 129 -65 0 -65 0 0 -51z"/>
<path d="M4430 4083 c1 -19 26 -79 35 -83 6 -3 11 15 13 43 3 45 2 47 -22 47
-15 0 -26 -3 -26 -7z"/>
<path d="M4490 4081 c0 -6 5 -13 10 -16 6 -4 10 -55 10 -120 0 -65 -4 -116
-10 -120 -27 -17 -2 -25 75 -25 l85 0 0 45 c0 48 -14 61 -24 21 -3 -13 -17
-31 -31 -40 l-25 -16 0 140 0 140 -45 0 c-25 0 -45 -4 -45 -9z"/>
<path d="M4590 4082 c0 -5 11 -18 25 -30 14 -12 25 -29 25 -37 0 -8 5 -15 10
-15 6 0 10 20 10 45 0 45 0 45 -35 45 -19 0 -35 -4 -35 -8z"/>
<path d="M4680 4081 c0 -6 5 -13 10 -16 6 -4 10 -55 10 -120 0 -65 -4 -116
-10 -120 -27 -17 -2 -25 75 -25 l85 0 0 44 c0 56 -15 67 -33 24 -8 -18 -21
-39 -30 -47 -16 -12 -17 -4 -17 112 0 73 4 128 10 132 24 15 6 25 -45 25 -30
0 -55 -4 -55 -9z"/>
<path d="M4865 3945 l0 -145 43 0 42 0 0 145 0 145 -42 0 -43 0 0 -145z"/>
<path d="M4960 4075 c0 -8 5 -15 10 -15 6 0 18 -13 29 -30 22 -36 31 -30 31
22 0 37 -1 38 -35 38 -24 0 -35 -5 -35 -15z"/>
<path d="M5454 4068 c11 -18 76 -242 76 -263 0 -3 4 -5 8 -5 10 0 42 154 41
195 0 25 -2 27 -8 10 -6 -16 -10 -9 -20 33 l-13 52 -49 0 c-49 0 -49 0 -35
-22z"/>
<path d="M5590 3961 c0 -76 -4 -132 -10 -136 -24 -15 -6 -25 45 -25 l55 0 -7
28 c-10 34 -10 187 -1 230 l8 32 -45 0 -45 0 0 -129z"/>
<path d="M6210 4082 c0 -4 4 -12 9 -17 7 -8 71 -244 71 -262 0 -2 3 -3 8 -3 9
0 42 156 41 196 0 25 -2 26 -8 9 -6 -16 -10 -9 -20 33 l-13 52 -44 0 c-24 0
-44 -4 -44 -8z"/>
<path d="M6350 3961 c0 -76 -4 -132 -10 -136 -24 -15 -6 -25 43 -25 l52 0 0
145 0 145 -42 0 -43 0 0 -129z"/>
<path d="M6570 4083 c0 -3 6 -14 13 -22 8 -9 38 -71 67 -138 46 -107 56 -123
76 -123 40 0 33 74 -18 193 l-41 97 -49 0 c-26 0 -48 -3 -48 -7z"/>
<path d="M6716 4073 c4 -10 9 -40 12 -68 6 -72 22 -81 22 -13 0 31 5 60 10 63
22 14 10 35 -21 35 -26 0 -30 -3 -23 -17z"/>
<path d="M4608 3981 c-17 -31 -17 -35 0 -65 l17 -31 3 33 c2 17 2 47 0 65 l-3
32 -17 -34z"/>
<path d="M4970 3976 c-10 -20 -10 -30 1 -55 20 -45 29 -38 29 24 0 59 -9 69
-30 31z"/>
<path d="M6598 3994 c-3 -3 -4 -32 -1 -64 4 -42 1 -67 -11 -90 -9 -18 -16 -35
-16 -37 0 -1 16 -3 35 -3 40 0 40 -1 20 36 -9 18 -14 53 -13 91 2 61 -2 79
-14 67z"/>
<path d="M5468 3905 c-2 -38 -8 -78 -12 -87 -7 -15 -3 -18 23 -18 31 0 43 21
21 35 -5 3 -10 27 -11 53 -1 83 -16 95 -21 17z"/>
<path d="M6228 3905 c-2 -38 -8 -78 -12 -87 -7 -15 -3 -18 23 -18 31 0 43 21
21 35 -5 3 -10 27 -11 53 -1 83 -16 95 -21 17z"/>
<path d="M3774 3939 c-3 -6 -1 -15 6 -19 11 -7 11 -69 -1 -110 -3 -10 2 -14
16 -12 34 6 52 35 59 95 l6 57 -39 0 c-22 0 -43 -5 -47 -11z"/>
<path d="M5002 3865 c-7 -14 -19 -28 -27 -31 -30 -12 -16 -34 20 -34 35 0 35
0 35 45 0 50 -11 58 -28 20z"/>
<path d="M5200 3870 c0 -6 -12 -22 -26 -35 -14 -13 -24 -29 -22 -36 5 -16 55
30 63 59 4 13 2 22 -4 22 -6 0 -11 -5 -11 -10z"/>
<path d="M5720 3855 c-11 -29 -5 -51 17 -59 34 -13 59 44 31 72 -19 19 -38 14
-48 -13z"/>
<path d="M6486 3864 c-20 -20 -20 -38 0 -58 12 -12 20 -13 35 -6 21 12 25 50
7 68 -16 16 -23 15 -42 -4z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
